import { NativeCurrency, Token } from '@uniswap/sdk-core'
import { getAddress } from 'viem'
import { polygonFork } from './wagmi.js'

export const NATIVE_CURRENCY_POLYGON = new NativeCurrency(polygonFork.id, 18, 'MATIC', 'Matic')
export const WRAPPED_NATIVE_CURRENCY_POLYGON = new Token(
  polygonFork.id,
  getAddress('0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'),
  18,
  'WMATIC',
  'Wrapped Matic'
)

export const WETH_POLYGON = new Token(
  polygonFork.id,
  getAddress('0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619'),
  18,
  'WETH',
  'Wrapped Ether'
)

export const USDC_POLYGON = new Token(
  polygonFork.id,
  getAddress('0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359'),
  6,
  'USDC',
  'USD Coin'
)

export const WBTC_POLYGON = new Token(
  polygonFork.id,
  getAddress('0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6'),
  8,
  'WBTC',
  'Wrapped Btc'
)

export const POLYGON_TOKENS = [
  NATIVE_CURRENCY_POLYGON,
  WRAPPED_NATIVE_CURRENCY_POLYGON,
  WETH_POLYGON,
  USDC_POLYGON,
  WBTC_POLYGON,
]

