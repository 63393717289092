import { connect, disconnect, getAccount, reconnect, watchAccount, switchChain } from '@wagmi/core'
import { Buffer } from 'buffer'

import { Wagmi } from '@/web3/config/wagmi.js'
import { RenderErrors } from '@/web3/ui/renderErrors.js'


globalThis.Buffer = Buffer

export class WalletManager {
  static async connect(connector) {
    try {
      await connect(Wagmi.config, { connector })
    } catch (error) {
      const account = getAccount(Wagmi.config)
      if (account.isConnected) {
        RenderErrors.addNewError(error.message, 'connect')
      }
    }
  }

  static disconnect() {
    disconnect(Wagmi.config)
  }

  static watchAccountChanges() {
    watchAccount(Wagmi.config, {
      onChange: async (account) => {
        RenderErrors.removeAllErrors()

        if (account.isConnected && !Wagmi.config.chains.some( chain => chain.id === account.chainId)) {
          // TODO: message hardcoded polygon network
          await switchChain(Wagmi.config,  { chainId: Wagmi.config.chains[0]})
          RenderErrors.addNewError('Please connect to the Polygon, ', 'wrongNetwork')
        }

        const event = new CustomEvent('accountChanged', { detail: account })
        document.dispatchEvent(event)
      },
    })
  }

  static reconnect() {
    reconnect(Wagmi.config).then().catch()
  }
}
