import IUniswapV3PoolABI from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json'
import { Controller } from '@hotwired/stimulus'
import { isAddressEqual, zeroAddress, formatUnits } from 'viem'
import { FeeAmount } from '@uniswap/v3-sdk'
import { put } from '@rails/request.js'
import {
  waitForTransactionReceipt,
  getTransactionReceipt,
  getTransaction,
  readContract,
  watchPendingTransactions
} from '@wagmi/core'
import {
  POLYGON_TOKENS,
  USDC_POLYGON,
  WRAPPED_NATIVE_CURRENCY_POLYGON,
} from '@/web3/config/tokens.js'

import { Wagmi } from '@/web3/config/wagmi.js'
import { bigIntify, fromReadableAmount } from '@/web3/utils/conversion.js'
import { ONE_PERCENT, percent_mul } from '@/web3/utils/percentage.js'

import { quoteExactOutput } from '@/web3/logic/uniswapQuote.js'
import { getTokenAllowance } from '@/web3/logic/getTokenAllowance.js'
import { approve } from '@/web3/logic/approve.js'
import { Checkout } from '@/web3/logic/checkout.js'
import { Charge } from '@/web3/logic/charge.js'

import { RenderCheckout } from '@/web3/ui/checkoutUi'

import { getNetworkFee } from '../../web3/logic/getNetworkFee'
import { getNativePriceUSD } from '../../web3/services/tokenPriceService'

import dateNow from '../../web3/utils/dateNow'

export default class extends Controller {
  static targets = [
    'token',
    'txHash',
    'paymentSuccess',
    'info',
    'paymentResult',
    'paymentDetails',
    'from',
    'network',
    'subtotal',
    'fee',
    'date',
    'status',
  ]
  static values = {
    tokenSymbol: String,
  }

  connect() {
    document.addEventListener('tokenSelected', (event) => {
      this.tokenSymbolValue = event.detail.tokenSymbol
    })
  }

  hideInfo() {
    this.infoTarget.classList.add('hidden')
    this.paymentResultTarget.classList.remove('hidden')
  }
  showInfo() {
    this.infoTarget.classList.remove('hidden')
    this.paymentResultTarget.classList.add('hidden')
  }

  async selectToken(event) {
    if (!this.tokenSymbolValue) {
      console.log('Error: no token selected')
    }
    const { contract_address, amount, payerAddress } = await this.getChargeDetails()

    const token = POLYGON_TOKENS.find(
      (token) => token.symbol.toLowerCase() == this.tokenSymbolValue.toLowerCase()
    )
    let inputAmount
    let maxWillingToPay
    if (isAddressEqual(token.address ?? zeroAddress, USDC_POLYGON.address)) {
      inputAmount = fromReadableAmount(amount, USDC_POLYGON.decimals)
    } else {
      const tokenIn = token.isNative ? WRAPPED_NATIVE_CURRENCY_POLYGON : token
      inputAmount = (
        await quoteExactOutput(
          fromReadableAmount(amount, USDC_POLYGON.decimals),
          tokenIn,
          USDC_POLYGON,
          FeeAmount.MEDIUM
        )
      ).result
      // add slippage
      maxWillingToPay = percent_mul(inputAmount, ONE_PERCENT) + bigIntify(inputAmount)
    }

    let hash
    if (!token.isNative) {
      const allowance = await getTokenAllowance(token.address, contract_address, payerAddress)
      if (allowance < maxWillingToPay) {
        hash = await approve(token.address, contract_address, maxWillingToPay)
        this.transationPending(hash)
      }
    }
    let checkout = new Checkout(await Charge.current)

    if (token.isNative) {
      hash = await checkout.swapAndTransferNative(maxWillingToPay)
    } else if (isAddressEqual(token.address, USDC_POLYGON.address)) {
      hash = await checkout.transferTokenPreApproved()
    } else {
      hash = await checkout.swapAndTransferToken(token.address, maxWillingToPay)
    }
    this.transationPending(hash)
  }

  // transationPending
  async transationPending(hash) {
    // rendering payment processing
    console.log(hash)
    await this.renderPending()

    const transaction = await waitForTransactionReceipt(Wagmi.config, { hash })
    console.log(transaction)
    try {
      console.log('work')
      const transaction = await waitForTransactionReceipt(Wagmi.config, { hash })
      this.transactionMinted(hash, transaction)
    } catch(err) {
      this.renderFail()
      console.log(err)
    }

  }

  async transactionMinted(hash, transaction) {
    this.renderSuccess(transaction)

    const { payerAddress, public_id, chain_id, amount, fee } = await this.getChargeDetails()
    console.log(public_id)

    const response = await put(window.location.href + '/charge/' + public_id, {
      body: JSON.stringify({ charge: { tx: hash.toString(), chain_id: chain_id } }),
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    })

    if (response.ok) {
      console.log('Success!!!', await response)
    }
  }

  async renderPending() {
    alert('pending')
    const { amount, chain_id } = await this.getChargeDetails()

    let network


    try {
      network = Wagmi.config.chains.filter((chain) => chain.id === chain_id)[0]
    } catch (err) {
      console.log(err)
    }

    this.fromTarget.innerHTML = RenderCheckout.paymentInfo('From', this.tokenSymbolValue)
    this.networkTarget.innerHTML = RenderCheckout.paymentInfo('Network', RenderCheckout.chain(network))
    this.subtotalTarget.innerHTML = RenderCheckout.paymentInfo('Subtotal', `$${amount}`)
    this.dateTarget.innerHTML = RenderCheckout.paymentInfo('Date', dateNow())
    this.statusTarget.innerHTML = RenderCheckout.paymentInfo('Status', RenderCheckout.status('pending'))

  }

  async renderSuccess(transition) {
    const { gasUsed, effectiveGasPrice } = transition

    // need to fix for polygon-fork
    try {
      const gasFee = BigInt(gasUsed) * BigInt(effectiveGasPrice)
      const priceUsd = await getNativePriceUSD(chain_id)
      const fee = gasFee * priceUsd
      this.feeTarget.innerHTML = RenderCheckout.paymentInfo('Network fee', fee)
    } catch (err) {
      console.log(err)
    }

    this.statusTarget.innerHTML = RenderCheckout.paymentInfo('Status', RenderCheckout.status('paid'))
  }

  async renderFail() {
    this.statusTarget.innerHTML = RenderCheckout.paymentInfo('Status', RenderCheckout.status('failed'))
  }

  async getChargeDetails() {
    const charge = await Charge.current

    const payerAddress = charge.web3_data.transfer_intent.metadata.sender
    const contract_address = charge.web3_data.transfer_intent.metadata.contract_address
    const amount = charge.pricing.settlement.amount
    const public_id = charge.web3_data.transfer_intent.metadata.public_id
    const chain_id = charge.web3_data.transfer_intent.metadata.chain_id
    const fee = charge.web3_data.transfer_intent.metadata.fee

    return { payerAddress, contract_address, amount, public_id, chain_id, fee }
  }
}
