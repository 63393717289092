import { Controller } from '@hotwired/stimulus'
import { getTokenPriceUSD } from '@/web3/services/tokenPriceService.js'
import { Wagmi } from '@/web3/config/wagmi'

export default class extends Controller {
  static targets = ['price', 'token']
  static values = {
    token: Object,
  }

  async connect() {
    this.initializeTokenVisibility()
    // this.scheduleTokenBalanceUpdates()
  }
  disconnect() {
    clearInterval(this.updateInterval)
  }

  initializeTokenVisibility() {
    // add hidden if token isn't native on load
    if (this.tokenValue.native) {
      // dispatch current Token
      this.element.classList.remove('hidden')
      this.dispatchEvent('tokenSelected', { tokenSymbol: this.tokenValue.symbol })
    }
  }

  // updating token balance
  scheduleTokenBalanceUpdates() {
    this.updateTokenBalanceDisplay()
    this.updateInterval = setInterval(() => {
      this.updateTokenBalanceDisplay()
    }, 10000) // each 10s
  }

  async updateTokenBalanceDisplay() {
    const token = this.tokenValue
    const chainId = Wagmi.config.state.chainId
    try {
      const usdcPrice = await getTokenPriceUSD(token, chainId)

      const balanceInUsdc = (token.token_balance * usdcPrice).toFixed(2)

      // Check balance and update or hide token display
      if (balanceInUsdc > 0) {
        this.priceTarget.innerHTML = `$${balanceInUsdc}`
        this.element.classList.remove('hide-element')
      } else {
        this.element.classList.add('hide-element')
      }
    } catch (error) {
      console.log(`Error ${error}:`)
    }
  }

  // help functions

  dispatchEvent(eventName, detail) {
    document.dispatchEvent(new CustomEvent(eventName, { detail }))
  }
}
