import { Controller } from '@hotwired/stimulus'
import { switchNetwork } from '@wagmi/core'
import { Wagmi, config } from '@/web3/config/wagmi.js'
import { RenderChain } from '@/web3/ui/renderChain'

import { RenderErrors } from '@/web3/ui/renderErrors.js'

import getChainById from '../helpers/getChainById';

export default class extends Controller {
  static outlets = ['visibility']
  static targets = ['currentChain', 'chains']
  static values = { activeChainId: Number }

  connect() {
    this.initializeDefaultChain()
    this.renderChains()
    this.updateCurrentChainDisplay();
    document.addEventListener('chainIdUpdated', this.updateChainId.bind(this));
  }

  initializeDefaultChain() {
    this.activeChainIdValue = Wagmi.config.chains[0].id;
  }

  toggleDropdown(e) {
    this.chainsTarget.classList.toggle('hidden')
  }

  renderChains() {
    const chainsHtml = Wagmi.config.chains.map(chain =>
      RenderChain.buttonHtml(chain, this.activeChainIdValue)
    ).join('');
    this.chainsTarget.innerHTML = chainsHtml;
  }

  updateCurrentChainDisplay() {
    const currentChain = getChainById(this.activeChainIdValue);
    this.currentChainTarget.innerHTML = RenderChain.currentChainImage(currentChain)
  }

  updateChainId(event) {
    const newChainId = event.detail.chainId;
    if (newChainId !== this.activeChainIdValue) {
      this.activeChainIdValue = newChainId;
      this.renderChains();
      this.updateCurrentChainDisplay();
    }
  }

  async switchChain(event) {
    const chainId = parseInt(event.currentTarget.dataset.chainId, 10);
    if(chainId === this.activeChainIdValue) return

    await this.attemptSwitchChain(chainId);
  }

  async attemptSwitchChain(chainId) {
    RenderErrors.removeError('wrongNetwork')
    this.visibilityOutlet.showLoader();
    try {
      const newChain = await switchNetwork(config(), { chainId });
      this.activeChainIdValue = newChain.id;
      this.renderChains();
      this.updateCurrentChainDisplay();
      this.toggleDropdown();
      // updating fee for new network
      this.dispatchEvent('drawMethods');
      this.dispatchEvent('showNetworkFee');
    } catch (error) {
      console.error('Failed to switch chain:', error);
      RenderErrors.addNewError('Something wen wrong! Try again or use other chain.', 'wrongNetwork')
    } finally {
      this.visibilityOutlet.hideLoader()
    }

  }

  dispatchEvent(eventName, detail) {
    document.dispatchEvent(new CustomEvent(eventName, { detail }));
  }
}
