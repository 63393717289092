import { getAddress } from 'viem'

// Uniswap Addresses
export const QUOTER_CONTRACT_ADDRESS = getAddress('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6')
export const PERMIT2_CONTRACT_ADDRESS = getAddress('0x000000000022D473030F116dDEE9F6B43aC78BA3')
export const UNISWAP_ROUTER_CONTRACT_ADDRESS = getAddress(
  '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD'
)

// TODO: replace by production data from database, etc.
export const TIME_INTERVAL = 108
export const CHAINS = {
  "1": {
    tokenout: {
      usdc: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
    }
  },
  "12345": {
	symbol: "MATIC",
    contract: "0xc21cfeecfa181a37ab1e6413a3647e675fb60cd0",
	gas: 253934,
    native: { name: "Wrapped Matic", symbol: "WMATIC", decimals: 18, address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270" },
    tokenout: {
      usdc: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
    }
  },

  "137": {
	symbol: "MATIC",
    contract: "0xc21cfeecfa181a37ab1e6413a3647e675fb60cd0",
	gas: 253934,
    native: { name: "Wrapped Matic", symbol: "WMATIC", decimals: 18, address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270" },
    tokenout: {
      usdc: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359"
    }
  }
};

export function getChainData(chainID) {
  return CHAINS[chainID];
}

