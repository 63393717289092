import { Controller } from '@hotwired/stimulus'
import { Wagmi } from '@/web3/config/wagmi.js'
import { getChainData } from '@/web3/config/constants.js'

import { getNetworkFee } from '../../web3/logic/getNetworkFee'
import { getNativePriceUSD } from '../../web3/services/tokenPriceService'

export default class  extends Controller {
  static targets = ['usd', 'native']

  async connect() {
    document.addEventListener('showNetworkFee', this.showNetworkFee.bind(this));
  }
  disconnect() {
    document.removeEventListener('showNetworkFee', this.showNetworkFee.bind(this));
  }

  async showNetworkFee () {
    const chainId = Wagmi.config.state.chainId
    const chain = getChainData(chainId)
    const priceUsd = await getNativePriceUSD(chainId)
    const feeNative = await getNetworkFee(chainId)

    this.nativeTarget.innerHTML = `~${feeNative} ${chain.symbol}`
    this.usdTarget.innerHTML = ` ~$${feeNative * priceUsd} USD`
  }
}
