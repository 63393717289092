import { post } from '@rails/request.js'

export class Charge {
  static current

  constructor(account) {
    if (!Charge.current) {
      Charge.current = this.getCharge(account)
    }
  }

  async getCharge(account) {
    const response = await post(window.location.href + '/charges', {
      body: JSON.stringify({ charge: { payee: account.address, chain_id: account.chainId } }),
    })

    let web3_data
    if (response.ok) {
      web3_data = await response.json
    }

    return web3_data
  }
}
