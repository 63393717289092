import { readContract, readContracts } from '@wagmi/core'
import { FeeAmount, Pool, tickToPrice } from '@uniswap/v3-sdk'
import IUniswapV3PoolABI from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json'
import { Wagmi } from '@/web3/config/wagmi.js'
import { TIME_INTERVAL } from '@/web3/config/constants.js'
import {
  NATIVE_CURRENCY_POLYGON,
  POLYGON_TOKENS,
  USDC_POLYGON,
  WRAPPED_NATIVE_CURRENCY_POLYGON,
} from '@/web3/config/tokens.js'
import { currentPoolAddress } from '@/web3/logic/uniswapQuote.js'

// Get prices for all network tokens except USDC
export const getNetworkTokensPrice = async () => {
  const filteredToken = POLYGON_TOKENS.filter((token) => token.symbol !== 'USDC')
  const prices = await Promise.all(
    filteredToken.map(async (token) => getTokenPrice(token, USDC_POLYGON))
  )
  return prices
}

// Get price for a specific token pair
export const getTokenPrice = async (tokenIn, tokenOut) => {
  if (tokenIn.isNative) return getNativeTokenPrice(tokenOut)
  const averages = await getAverages(tokenIn, tokenOut)
  return {
    token: tokenIn,
    averages,
  }
}

// Special handling for native token prices
export const getNativeTokenPrice = async (tokenOut) => {
  const averages = await getAverages(WRAPPED_NATIVE_CURRENCY_POLYGON, tokenOut)
  return {
    token: NATIVE_CURRENCY_POLYGON,
    averages,
  }
}

// Calculate averages using Uniswap V3 Pool data
export const getAverages = async (tokenIn, tokenOut) => {
  const poolAddress = currentPoolAddress(tokenIn, tokenOut, FeeAmount.MEDIUM)
  const observations = await observe(TIME_INTERVAL, poolAddress, tokenIn.chainId)
  const uniswapPoolContract = {
    address: poolAddress,
    abi: IUniswapV3PoolABI.abi,
    chainId: tokenIn.chainId,
  }

  const data = await readContracts(Wagmi.config, {
    contracts: [
      {
        ...uniswapPoolContract,
        functionName: 'slot0',
      },
      {
        ...uniswapPoolContract,
        functionName: 'liquidity',
      },
      {
        ...uniswapPoolContract,
        functionName: 'token0',
      },
    ],
  })

  const slot0 = data[0].result
  const liquidity = data[1].result
  const token0 = data[2].result

  const pool = new Pool(
    tokenIn,
    tokenOut,
    FeeAmount.MEDIUM,
    slot0[0].toString(),
    liquidity.toString(),
    slot0[1]
  )

  const twap = calculateTWAP(observations, pool)
  const twal = calculateTWAL(observations)

  return { twap, twal, invert: token0 == tokenOut.address }
}

const observe = async (secondsAgo, poolAddress, chainId) => {
  const timestamps = [0, secondsAgo]

  const response = await readContract(Wagmi.config, {
    abi: IUniswapV3PoolABI.abi,
    address: poolAddress,
    functionName: 'observe',
    args: [timestamps],
    chainId: chainId,
  })
  const observations = timestamps.map((time, i) => {
    return {
      secondsAgo: time,
      tickCumulative: BigInt(response[0][i]),
      secondsPerLiquidityCumulativeX128: BigInt(response[1][i]),
    }
  })
  return observations
}

function calculateTWAP(observations, pool) {
  const diffTickCumulative = observations[0].tickCumulative - observations[1].tickCumulative
  const secondsBetween = observations[1].secondsAgo - observations[0].secondsAgo
  const averageTick = Number(diffTickCumulative / BigInt(secondsBetween))

  return tickToPrice(pool.token0, pool.token1, averageTick)
}

function calculateTWAL(observations) {
  const diffSecondsPerLiquidityX128 =
    observations[0].secondsPerLiquidityCumulativeX128 -
    observations[1].secondsPerLiquidityCumulativeX128

  const secondsBetween = observations[1].secondsAgo - observations[0].secondsAgo
  const secondsBetweenX128 = BigInt(secondsBetween) << BigInt(128)

  return secondsBetweenX128 / diffSecondsPerLiquidityX128
}
