import { Controller } from '@hotwired/stimulus'
import { UIConnectButtons } from '@/web3/ui/renderConnectButtons.js'
import { WalletManager } from '@/web3/logic/wallet_manager.js'
import { Charge } from '@/web3/logic/charge.js'
import { Wagmi } from '@/web3/config/wagmi'

import getChainById from '@/controllers/helpers/getChainById'
import getChainLogoPath from '@/controllers/helpers/getChainLogoPath'


export default class extends Controller {
  static outlets = ['visibility']
  static targets = [
    'status',
    'address',
    'chain',
    'chainLogo',
    'formattedAddress',
    'disconnect',
    'txHash',
    'connectors',
  ]
  static values = {
    walletInfo: Object,
    networks: Array,
  }

  connect() {
    this.initializeWagmi();
    this.setupEventListeners();
    WalletManager.watchAccountChanges()
    WalletManager.reconnect()
    this.visibilityOutlet.hideLoader()
  }

  initializeWagmi() {
    new Wagmi(this.networksValue);
  }

  setupEventListeners() {
    document.addEventListener('accountChanged', async (e) => this.handleAccountChange(e.detail));
  }

  handleAccountChange(account) {
    account.isConnected ? this.onConnectAccount(account) : this.onDisconnectAccount();
  }


  async onConnectAccount(account) {
    this.updateWalletInfo({
      status: 'Connected',
      address: account.address,
      chainId: account.chainId
    });
    await this.processAccountConnection(account);
  }

  async processAccountConnection(account) {
    this.visibilityOutlet.showLoader();
    this.connectorsTarget.innerHTML = '';
    await this.getCharge(account);
    this.visibilityOutlet.onConnectView();
  }


  onDisconnectAccount() {
    this.visibilityOutlet.showLoader();
    new UIConnectButtons(this.connectorsTarget).renderButtons();
    this.updateWalletInfo({
      status: 'Not connected',
      address: '',
      chainId: ''
    });
    this.visibilityOutlet.defaultView();
    this.visibilityOutlet.hideLoader();
  }

  updateWalletInfo(info) {
    this.walletInfoValue = info;
    this.displayWalletStatus();
    this.displayFormattedAddress();
    this.displayChainInfo();
  }

  displayWalletStatus() {
    if (this.hasStatusTarget) this.statusTarget.textContent = this.walletInfoValue.status;
  }

  displayFormattedAddress() {
    const address = this.walletInfoValue.address;
    if (this.hasFormattedAddressTarget && address) {
      this.formattedAddressTarget.textContent = `${address.substring(0, 13)}...${address.substring(address.length - 5)}`;
    }
  }

  displayChainInfo() {
    if (this.walletInfoValue.chainId) {
      const chain = getChainById(this.walletInfoValue.chainId);
      const chainLogoSrc = getChainLogoPath(chain.name);
      if (this.hasChainTarget) {
        this.chainTarget.innerHTML = `<img src="${chainLogoSrc}" /><span>${chain.name}</span>`;
      }
      if (this.hasChainLogoTarget) {
        this.chainLogoTarget.innerHTML = `<img src="${chainLogoSrc}" class="object-contain w-full h-full" />`;
      }
    }
  }

  async getCharge(account) {
    await new Charge(account);
    this.dispatchEvent('chainIdUpdated', { chainId: account.chainId });
    this.dispatchEvent('drawMethods');
  }

  disconnectWallet() {
    WalletManager.disconnect();
    this.visibilityOutlet.showLoader();
    this.visibilityOutlet.defaultView();
    this.visibilityOutlet.hideLoader();
  }


  dispatchEvent(eventName, detail) {
    document.dispatchEvent(new CustomEvent(eventName, { detail }));
  }
}
