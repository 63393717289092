import { Controller } from '@hotwired/stimulus'
import { Wagmi } from '@/web3/config/wagmi'
import { Charge } from '@/web3/logic/charge.js'
import { put } from '@rails/request.js'

export default class extends Controller {
  static outlets = ['visibility']
  static targets = ['tokens', 'token']
  static values = {
    showTokens: false,
  }

  async connect() {
    document.addEventListener('drawMethods', this.drawMethods.bind(this))
  }
  disconnect() {
    document.removeEventListener('drawMethods', this.drawMethods.bind(this))
  }

  handleClick(e) {
    this.visibilityOutlet.showLoader()

    if (this.showTokensValue) {
      this.selectToken(e.currentTarget)
    } else {
      this.showTokens()
    }
    this.visibilityOutlet.hideLoader()
  }

  showTokens() {
    this.showTokensValue = true
    this.updateTokenVisibility()
    this.visibilityOutlet.showTokens()
  }

  updateTokenVisibility() {
    this.tokenTargets.forEach((token) => {
      token.classList.toggle('hidden', !this.showTokensValue)
    })
  }

  selectToken(selectedToken) {
    const tokenSymbol = selectedToken.dataset.symbol

    this.showTokensValue = false
    this.tokenTargets.forEach((token) => {
      const shouldBeHidden = token !== selectedToken
      token.classList.toggle('hidden', shouldBeHidden)
    })

    // updating token symbol for checkout
    if (tokenSymbol) {
      this.dispatchEvent('tokenSelected', { tokenSymbol })
    }

    this.visibilityOutlet.hideTokens()
  }

  async drawMethods() {
    this.visibilityOutlet.showLoader()
    const charge = await Charge.current
    const payerAddress = charge.web3_data.transfer_intent.metadata.sender
    const chainId = Wagmi.config.state.chainId

    await put(window.location.origin + '/wallet', {
      body: JSON.stringify({ address: payerAddress, chain_id: chainId }),
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    })

    this.dispatchEvent('showNetworkFee')
    this.visibilityOutlet.hideLoader()
  }

  async turboGetTokens() {

  }

  dispatchEvent(eventName, detail) {
    document.dispatchEvent(new CustomEvent(eventName, { detail }))
  }
}
