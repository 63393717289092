import { getGasPrice, prepareTransactionRequest } from '@wagmi/core'
import { formatUnits } from 'viem'

import { Wagmi } from '@/web3/config/wagmi.js'
import { Charge } from '@/web3/logic/charge.js'

import { getNativePriceUSD } from '@/web3/services/tokenPriceService'

export async function getNetworkFee(chainId) {
  const { sender, contractAddress, amount } = await getChargeDetails()

  const gasPrice = await getGasPrice(Wagmi.config) // Make sure this returns BigInt
  const priceUsd = await getNativePriceUSD(chainId)

  const sendingAmount = priceUsd * amount

  const valueInWei = BigInt(Math.floor(sendingAmount * 1e18))



  const transactionRequest = await prepareTransactionRequest(Wagmi.config, {
    account: sender,
    to: contractAddress,
    value: valueInWei,
  })

  if (!transactionRequest.gas) {
    throw new Error('Gas estimate not returned')
  }
  const networkFee = formatUnits(transactionRequest.gas * gasPrice)

  return networkFee
}

// help functions
async function getChargeDetails() {
  const charge = await Charge.current

  const sender = charge.web3_data.transfer_intent.metadata.sender
  const contractAddress = charge.web3_data.transfer_intent.metadata.contract_address
  const amount = charge.pricing.settlement.amount

  return { sender, contractAddress, amount }
}
