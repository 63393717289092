import { coinbaseWallet, injected, safe } from '@wagmi/connectors'
import { createConfig, http } from '@wagmi/core'
import { mainnet, polygon  } from '@wagmi/core/chains'

export const polygonFork = {
  ...polygon,
  name: 'Polygon Fork',
  id: 12345,
  rpcUrls: {
    default: { http: ['https://rpc.tenderly.co/fork/9e42592e-168a-490a-9fcd-1637dc59da61'] },
  },
}

export const connectors = [injected(), safe(), coinbaseWallet({ appName: 'ScionX' })]

export class Wagmi {
  static SUPPORTED_NETWORKS_IDS = [polygonFork.id, polygon.id];
  static config = createConfig({
    chains: [polygonFork, polygon],
    connectors: connectors,
    transports: {
        [polygonFork.id]: http(),
        [polygon.id]: http(),
    },
  })

  static tokenOut(tokenName, chainId) {
     if (this.TOKENOUTS[tokenName] && this.TOKENOUTS[tokenName][chainId]) {
       return this.TOKENOUTS[tokenName][chainId].address;
     }
      throw new Error("Token or chain ID not supported.");
   }
}


export function config() {
  return Wagmi.config
}

