import { getAddress } from 'viem'
import { getTokenPrice } from '@/web3/logic/getTokenPrice.js'
import { Token } from '@uniswap/sdk-core'
import { getChainData } from '@/web3/config/constants.js'

export async function getNativePriceUSD(chainId) {
  try {
    const { native } = getChainData(chainId);
    return await getTokenPriceUSD(native, chainId);
  } catch (error) {
    console.error('Error getting native price USD:', error);
    throw error;
  }
}

export async function getTokenPriceUSD(token, chainId) {
  const tokenIn = createTokenInstance(token, chainId)
  if (tokenIn.symbol === 'USDC') return 1

  const tokenOut = createUsdcToken(chainId)

  const { averages } = await getTokenPrice(tokenIn, tokenOut)


  if (!averages) return 1
  const usdcPrice = extractSignificantPrice(averages)

  return usdcPrice
}


// creating token instance
export function createTokenInstance(token, chainId) {
  return new Token(
    chainId,
    getAddress(token.contract_address || token.address), // TODO fix in constants used address.
    token.decimals,
    token.symbol,
    token.name
  )
}

// creating usdc token
export function createUsdcToken(chainId) {
  return new Token(chainId, getAddress(getChainData(chainId).tokenout.usdc), 6, 'USDC', 'USD Coin')
}

export function extractSignificantPrice(averages) {
  if (!averages) {
    console.log(`Error: averages ${averages}`)
    return
  }

  const result = averages.invert
    ? averages.twap.invert().toSignificant()
    : averages.twap.toSignificant()

  return result
}
